<template>
  <div class="sidebar">
    <div class="sidebar-top">
      <div class="user-icon">{{ userLabel }}</div>
      <div class="text-center">
        <h4>{{ userDoc.name }}</h4>
        <p>{{ userDoc.email }}</p>
      </div>
    </div>
    <div class="sidebar-nav">
      <router-link :to="{ name: 'Dashboard' }">
        <div
          @click="changeActive(dash)"
          v-bind:class="{ active: menus[dash] }"
          class="jbtn-nav"
        >
          <i class="fad fa-credit-card"></i
          ><span class="ml-3">Subscription</span>
        </div>
      </router-link>

      <router-link :to="{ name: 'Account' }">
        <div
          @click="changeActive(account)"
          v-bind:class="{ active: menus[account] }"
          class="jbtn-nav"
        >
          <i class="fa fa-user"></i><span class="ml-3">Account</span>
        </div>
      </router-link>

      <router-link :to="{ name: 'Password' }">
        <div
          @click="changeActive(password)"
          v-bind:class="{ active: menus[password] }"
          class="jbtn-nav"
        >
          <i class="fad fa-lock"></i><span class="ml-3">Password</span>
        </div>
      </router-link>

      <router-link :to="{ name: 'Invoices' }">
        <div
          @click="changeActive(invoices)"
          v-bind:class="{ active: menus[invoices] }"
          class="jbtn-nav"
        >
          <i class="fad fa-receipt"></i><span class="ml-3">Invoices</span>
        </div>
      </router-link>

      <router-link :to="{ name: 'Download' }">
        <div
          @click="changeActive(download)"
          v-bind:class="{ active: menus[download] }"
          class="jbtn-nav"
        >
          <i class="fad fa-download"></i
          ><span class="ml-3">Download Center</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import C from "@/constants";
export default {
  data() {
    return {
      modeProspect: C.MODE.CONSULT_PROSPECTS,
      dash: 0,
      sub: 1,
      account: 2,
      password: 3,
      invoices: 4,
      download: 5,

      active: 0,
      menus: [true, false, false, false, false, false],
      type: this.$store.getters.getMode,
    };
  },
  methods: {
    changeActive: function(clicked) {
      console.log(clicked);

      this.$set(this.menus, this.active, false);
      this.$set(this.menus, clicked, true);
      this.active = clicked;

      console.log(this.active);
      console.log(this.menus);
    },
    // display different options depending on user type, display mode
    isAdmin: function() {
      return this.$store.getters.getMode == C.MODE.CONSULT_PROSPECT;
    },
  },
  computed: {
    userDoc: {
      get() {
        return this.$store.getters.getUser;
      },
    },
    userLabel: {
      get() {
        return this.$store.getters.getUserLabel;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/sidebar.scss";
</style>

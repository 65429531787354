<template>
  <div class="layout-wrapper">
    <Navbar></Navbar>
    <div class="content row no-gutters">
      <Sidebar class="col-lg-3"></Sidebar>

      <div class="col-lg-9" style="background: #1193f5;">
        <!-- <div class="content-container shadow p-3 mb-3 bg-white rounded"> -->
        <router-view></router-view>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import firebase from "firebase/app";
import db from "@/firebase/firebaseInit";
// import C from "@/constants";
import Sidebar from "@/components/navigation/Sidebar";

export default {
  name: "User",
  components: {
    Sidebar: Sidebar,
    Navbar: Navbar,
  },
  data() {
    return {};
  },
  methods: {
    loadFirebaseUser() {
      if (!firebase.auth().currentUser) {
        // this.isLoggedIn = false;
      } else {
        //get user id from firebase auth
        this.userId = firebase.auth().currentUser.uid;
        // this.isLoggedIn = true;

        //get user document from firestore
        var docRef = db.collection("users").doc(this.userId);
        docRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              console.log("firebase GET userDoc SUCCESS");
              // save userDoc in store
              this.$store.commit("setUser", doc.data());
            } else {
              // doc.data() will be undefined in this case
              console.log("UserDoc not found!");
            }
          })
          .catch((error) => {
            console.log("firebase GET userDoc ERROR: ", error);
          });
      }
    },
  },
  computed: {
    userDoc: {
      get() {
        return this.$store.getters.getUser;
      },
    },
  },
  created() {
    this.loadFirebaseUser();

    //set dashboard mode to true
    this.$store.commit("setIsDash", true);
  },
};
</script>

<style scoped></style>
